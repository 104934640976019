jQuery(function($){
    const ua = window.navigator.userAgent;
    let os, device, version, matched;

    if (matched = ua.match(/Windows NT (\d+\.\d+)/)) {
        os = 'Windows';
        device = 'desktop';
        switch (matched[1]) {
            case '5.1':
            case '5.2':
                version = 'XP';
                break;
            case '6.0':
                version = 'Vista';
                break;
            case '6.1':
                version = '7';
                break;
            case '6.2':
                version = '8';
                break;
            case '6.3':
                version = '8.1';
                break;
            case '10.0':
                version = '10';
                break;
        }
    }
    else if (matched = ua.match(/Mac OS X (\d+[_.]\d+)/)) {
        os = 'Mac OS';
        device = 'desktop';
        version = matched[1].replace(/_/g, '.');
    }
    else if (matched = ua.match(/iPhone OS ((1)?\d_\d)/)) {
        os = 'iOS';
        device = 'mobile';
        version = matched[1].replace(/_/g, '.');
    }
    else if (matched = ua.match(/iPad; CPU OS ((1)?\d_\d)/)) {
        os = 'iOS';
        device = 'tablet';
        version = matched[1].replace(/_/g, '.');
    }
    else if (matched = ua.match(/Android (\d(\.\d)?)/)) {

        console.log(matched[1])

        os = 'Android';
        version = matched[1];
        if (ua.indexOf('Mobile') > 0) {
            device = 'mobile';
        } else {
            device = 'tablet';
        }
    }

    // ブラウザ判定
    let ua2l = ua.toLowerCase();
    let isMSIE = (ua2l.indexOf('msie') > -1) && (ua2l.indexOf('opera') == -1);
    let isIE11 = (ua2l.indexOf('trident/7') > -1);

    // IE
    let isIE = isMSIE || isIE11;
    // Edge
    let isEdge = (ua2l.indexOf('edge') > -1);
    // Google Chrome
    let isChrome = (ua2l.indexOf('chrome') > -1) && (ua2l.indexOf('edge') == -1);
    // Firefox
    let isFirefox = (ua2l.indexOf('firefox') > -1);
    // Safari
    let isSafari = (ua2l.indexOf('safari') > -1) && (ua2l.indexOf('chrome') == -1);
    // Opera
    let isOpera = (ua2l.indexOf('opera') > -1);

    if (device === 'tablet') document.querySelector("[name=viewport]").setAttribute("content", "width=1366");
    document.body.setAttribute('data-os', os + ' ' + version);
    document.body.setAttribute('data-device', device);

    jQuery('body').addClass(device).addClass("ua-" + os.toLowerCase().replace(" ", ""));

    if(isIE || isEdge) {
        jQuery('body').addClass("ua-ie");
    }


    // スクロール
    jQuery('.scroll').on('click', function() {
        let speed = 500;
        let href = jQuery(this).attr("href");
        let target = jQuery(href === "#" || href === "" ? 'html' : href);

        let position = target.offset().top;

        // mobile時、header高さ調整
        if (device === 'mobile') position -= 71;

        jQuery("html, body").animate({scrollTop:position}, speed, "swing");
        return false;
    });

    // スクロールカスタマイズ（全てのブラウザに対応するためjsで実装）
    jQuery(window).on("load",function(){
        jQuery(".c-modal ._scroll").mCustomScrollbar({
            theme:"dark",
            axis:"y"
        });
    });


    // ハンバーガーメニュー
    jQuery('.l-header-humbarger').on('click', function(){
        jQuery('.menu-main-menu-container').toggle();
        jQuery(".l-header").toggleClass("menu-active");

        // ナビが表示された時に背面のページがスクロールしないように
        if(jQuery(".l-header").hasClass("menu-active") === true){
            jQuery("html, body").css({"overflow": "overlay", "height": "100%"});
        }else{
            jQuery("html, body").css({"overflow": "overlay", "height": "auto"});
        }
    });


    // モーダル
    jQuery('.js-modal').on('click', function(e){
        e.preventDefault();
        let modal = e.currentTarget.dataset.modal;
        jQuery('.c-modal.modal-' + modal).addClass('show');
    });

    jQuery('.c-modal').on('click', function(e){
        if(e.target === e.currentTarget){
            jQuery('.c-modal').removeClass('show');
            return false;
        }
    });

    jQuery('.c-modal-close').on('click', function(e){
        jQuery('.c-modal').removeClass('show');
        return false;
    });

}());
